var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "contact-page",
    class: _vm.$mq
  }, [_c('img', {
    staticClass: "contact-page__background",
    class: _vm.$mq,
    attrs: {
      "src": require(`@/assets/images/index_bg.svg`)
    }
  }), _c('div', {
    staticClass: "lights"
  }, [_c('svg', {
    attrs: {
      "width": "1440",
      "height": "770",
      "viewBox": "0 0 1440 770",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "opacity": "0.5",
      "filter": "url(#filter0_f_1201:7621)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M588.078 227.574C588.078 227.574 622.205 307.177 612.14 336.215C602.076 365.253 309.789 448.779 286.111 440.573C262.434 432.366 261.838 361.584 271.902 332.546C281.966 303.508 588.078 227.574 588.078 227.574Z",
      "fill": "#A09EFF",
      "fill-opacity": "0.85"
    }
  })]), _c('g', {
    attrs: {
      "opacity": "0.5",
      "filter": "url(#filter1_f_1201:7621)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M1413.08 -93.4255C1413.08 -93.4255 1447.2 -13.823 1437.14 15.2152C1427.08 44.2533 1134.79 127.779 1111.11 119.573C1087.43 111.366 1086.84 40.584 1096.9 11.5459C1106.97 -17.4923 1413.08 -93.4255 1413.08 -93.4255Z",
      "fill": "#FFECF3",
      "fill-opacity": "0.85"
    }
  })]), _c('defs', [_c('filter', {
    attrs: {
      "id": "filter0_f_1201:7621",
      "x": "-62.273",
      "y": "-100.426",
      "width": "1004.27",
      "height": "869.562",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "BackgroundImageFix",
      "result": "shape"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "164",
      "result": "effect1_foregroundBlur_1201:7621"
    }
  })], 1), _c('filter', {
    attrs: {
      "id": "filter1_f_1201:7621",
      "x": "762.727",
      "y": "-421.426",
      "width": "1004.27",
      "height": "869.562",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "BackgroundImageFix",
      "result": "shape"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "164",
      "result": "effect1_foregroundBlur_1201:7621"
    }
  })], 1)])])]), _c('public-header'), _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "title",
    class: _vm.$mq
  }, [_vm._v("Свяжитесь с нами")]), _c('div', {
    staticClass: "card light",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "contacts",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "contacts__text",
    class: _vm.$mq
  }, [_vm._v(" Мы ценим наших пользователей и всегда рады вашим вопросам, отзывам и предложениям по работе сервиса SalesFinder ")]), _c('div', {
    staticClass: "contacts-item"
  }, [_c('div', {
    staticClass: "contacts-item__title"
  }, [_vm._v(" По вопросам продаж ")]), _c('div', {
    staticClass: "contacts-item__element"
  }, [_c('div', {
    staticClass: "contacts-item__image"
  }, [_c('svg', {
    attrs: {
      "width": "20",
      "height": "16",
      "viewBox": "0 0 20 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M18 0H2C0.897 0 0 0.897 0 2V14C0 15.103 0.897 16 2 16H18C19.103 16 20 15.103 20 14V2C20 0.897 19.103 0 18 0ZM18 2V2.511L10 8.734L2 2.512V2H18ZM2 14V5.044L9.386 10.789C9.566 10.93 9.783 11 10 11C10.217 11 10.434 10.93 10.614 10.789L18 5.044L18.002 14H2Z",
      "fill": "#BDBDBD"
    }
  })])]), _c('a', {
    staticClass: "contacts-item__link",
    attrs: {
      "href": "mailto:sales@salesfinder.ru",
      "target": "_blank"
    }
  }, [_vm._v(" sales@salesfinder.ru ")])])]), _c('div', {
    staticClass: "contacts-item"
  }, [_c('div', {
    staticClass: "contacts-item__title"
  }, [_vm._v(" Для СМИ и партнеров ")]), _c('div', {
    staticClass: "contacts-item__element"
  }, [_c('div', {
    staticClass: "contacts-item__image"
  }, [_c('svg', {
    attrs: {
      "width": "20",
      "height": "16",
      "viewBox": "0 0 20 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M18 0H2C0.897 0 0 0.897 0 2V14C0 15.103 0.897 16 2 16H18C19.103 16 20 15.103 20 14V2C20 0.897 19.103 0 18 0ZM18 2V2.511L10 8.734L2 2.512V2H18ZM2 14V5.044L9.386 10.789C9.566 10.93 9.783 11 10 11C10.217 11 10.434 10.93 10.614 10.789L18 5.044L18.002 14H2Z",
      "fill": "#BDBDBD"
    }
  })])]), _c('a', {
    staticClass: "contacts-item__link",
    attrs: {
      "href": "mailto:marketing@salesfinder.ru",
      "target": "_blank"
    }
  }, [_vm._v(" marketing@salesfinder.ru ")])])]), _c('div', {
    staticClass: "contacts-item"
  }, [_c('div', {
    staticClass: "contacts-item__title"
  }, [_vm._v(" Поддержка пользователей ")]), _c('div', {
    staticClass: "contacts-item__element"
  }, [_c('div', {
    staticClass: "contacts-item__image"
  }, [_c('svg', {
    attrs: {
      "width": "20",
      "height": "16",
      "viewBox": "0 0 20 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M18 0H2C0.897 0 0 0.897 0 2V14C0 15.103 0.897 16 2 16H18C19.103 16 20 15.103 20 14V2C20 0.897 19.103 0 18 0ZM18 2V2.511L10 8.734L2 2.512V2H18ZM2 14V5.044L9.386 10.789C9.566 10.93 9.783 11 10 11C10.217 11 10.434 10.93 10.614 10.789L18 5.044L18.002 14H2Z",
      "fill": "#BDBDBD"
    }
  })])]), _c('a', {
    staticClass: "contacts-item__link",
    attrs: {
      "href": "mailto:support@salesfinder.ru",
      "target": "_blank"
    }
  }, [_vm._v(" support@salesfinder.ru ")])]), _c('div', {
    staticClass: "contacts-item__element"
  }, [_c('div', {
    staticClass: "contacts-item__image"
  }, [_c('svg', {
    attrs: {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "width": "24",
      "height": "24",
      "rx": "4",
      "fill": "url(#paint0_linear_1020:8228)"
    }
  }), _c('path', {
    attrs: {
      "d": "M18.9573 7.09244L16.6937 17.2756C16.5229 17.9943 16.0775 18.1732 15.4447 17.8346L11.9957 15.4102L10.3315 16.937C10.1473 17.1127 9.99329 17.2597 9.63835 17.2597L9.88614 13.9089L16.2784 8.39888C16.5564 8.16251 16.2182 8.03154 15.8465 8.26792L7.944 13.0145L4.54191 11.9988C3.80189 11.7784 3.7885 11.2928 4.69594 10.9543L18.0029 6.0639C18.6191 5.8435 19.1582 6.19487 18.9573 7.09244Z",
      "fill": "white"
    }
  }), _c('defs', [_c('linearGradient', {
    attrs: {
      "id": "paint0_linear_1020:8228",
      "x1": "12",
      "y1": "0",
      "x2": "12",
      "y2": "24",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "#279EF1"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#208CD2"
    }
  })], 1)], 1)])]), _c('a', {
    staticClass: "contacts-item__link",
    attrs: {
      "href": "https://t.me/SalesFinderSupportBot",
      "target": "_blank"
    }
  }, [_vm._v(" SalesFinderSupportBot ")])]), _c('button', {
    staticClass: "support-chat-btn",
    on: {
      "click": _vm.openCarrotquestChat
    }
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/images/icons/chat.svg')
    }
  }), _c('span', [_vm._v("Чат с поддержкой")])])])])])]), _c('public-footer')], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };